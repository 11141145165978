<template>
  <v-app>
    <v-dialog
      v-model="dialogLoading"
      hide-overlay
      v-if="loading"
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-text>
          <h2 class="white--text">Loading</h2>
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

        <v-system-bar class="d-print-none pt-3" height="30" app lights-out>
      <v-toolbar-items class="text-right">
        <v-btn
          large
          rounded
          dark
          href="javascript:window.print()"
          class="title mr-2 rounded"
          color="info"
        >
          <v-icon class="mr-1" color="white" large>mdi-printer</v-icon>พิมพ์
        </v-btn>
      </v-toolbar-items>
    </v-system-bar>

    <div id="exportContent" style="mso-page-orientation: portrait;">
      <div class="page">
        <div v-for="(item, index) in transference_personnels" :key="index">
          <div class="text_l bold16 text-center mt-10">
            แบบบันทึกคะแนน องค์ประกอบ ตัวชี้วัดและคะแนนในการตรวจสอบ <br />
            ตามองค์ประกอบการประเมินศักยภาพของผู้ประสงค์ขอย้าย
            <br />
            ตำแหน่งครู (การย้ายกรณีปกติ) ขององค์ประกอบที่ 1 - 6
            <br>
              ย้ายประจำปี {{ periods.period_vecprovince_year }} ครั้งที่ {{ periods.period_vecprovince_times }}

          </div>

          <table width="100%" class="text_j">
            <tr>
              <td width="20%" class="bold16" align="left">
                ชื่อ-นามสกุล ผู้ขอย้าย
              </td>
              <td width="35%" class="td_line" align="center">
                {{ item.title_s + item.frist_name + " " + item.last_name }}
              </td>
              <td width="15%" class="bold16" align="center">เลขที่ตำแหน่ง</td>
              <td class="td_line text-center">{{ item.id_position }}</td>
            </tr>
            <tr>
              <td colspan="2" class="td_line text-center">
                {{ item.college_name }}
              </td>
            </tr>
          </table>

          <table width="100%" class="table text_j mt-2">
            <tr>
              <td width="5%" class="bold16 table" align="center">ที่</td>
              <td width="70%" class="bold16 table" align="center">
                ตัวชี้วัด / องค์ประกอบ
              </td>             
              <td class="bold16 table" align="center">คะแนนเต็ม</td>
              <td class="bold16 table" align="center">คะแนนที่ได้</td>
            </tr>
            <tr>
              <td class="text_al text-center table bold16">1</td>
              <td class="pa-1 table text_al bold16">
                เหตุผลในการย้าย 
              </td>
              <td class="pa-1 table text_al bold16 text-center">30</td>
              <td class="pa-1 table text_al bold16"></td>
            </tr>          
            <tr>
              <td class="text_al text-center table bold16">2</td>
              <td class="pa-1 table text_al bold16">
                ผลการปฏิบัติงานที่เกิดกับผู้เรียนและสถานศึกษาปัจจุบัน 
              </td>             
              <td class="pa-1 table text_al bold16 text-center">10</td>
              <td class="pa-1 table text_al bold16"></td>
            </tr>
            <tr>
              <td class="text_al text-center table bold16">3</td>
              <td class="pa-1 table text_al bold16">
                สภาพความยากลำบากในการปฏิบัติงานในสถานศึกษาปัจจุบัน
              </td>             
              <td class="pa-1 table text_al bold16 text-center">5</td>
              <td class="pa-1 table text_al bold16"></td>
            </tr>
            <tr>
              <td class="text_al text-center table bold16">4</td>
              <td class="pa-1 table text_al bold16">
                ระยะเวลาการปฏิบัติหน้าที่สอนในสถานศึกษาปัจจุบัน
              </td>             
              <td class="pa-1 table text_al bold16 text-center">5</td>
              <td class="pa-1 table text_al bold16"></td>
            </tr>
            <tr>
              <td class="text_al text-center table bold16">5</td>
              <td class="pa-1 table text_al bold16">
                การรักษาวินัย คุณธรรม จริยธรรม และจรรยาบรรณวิชาชีพ
              </td>
              <td class="pa-1 table text_al bold16 text-center">5</td>
              <td class="pa-1 table text_al bold16"></td>
            </tr>           
            <tr>
              <td class="text_al text-center table bold16">6</td>
              <td class="pa-1 table text_al bold16">
                อายุราชการ
              </td>
             
              <td class="pa-1 table text_al bold16 text-center">5</td>
              <td class="pa-1 table text_al bold16"></td>
            </tr>
           
            <tr>
              <td colspan="2" class="pa-1 table text_al bold16 text-center">
                รวมคะแนน
              </td>
             
              <td class="pa-1 table text_al bold16 text-center">60</td>
              <td class="pa-1 table text_al bold16"></td>
            </tr>
          </table>

          <br />
          <table class="text_j" width="100%" align="center">
            <tr>
              <td class="bold16">
                รับรองข้อมูลตามแบบบันทึกผลรวมคะแนนการประเมินคุณลักษณะที่จำเป็นต่อการปฏิบัติงาน
              </td>
            </tr>

            <!-- <span v-if="itemCommitee.commiteeVecProPosition === '1'"
                        >ประธานกรรมการ</span
                      >
                      <span v-else>กรรมการ</span> -->
          </table>

          <v-row class="pt-2">
            <v-col
              cols="4"
              md="4"
              v-for="item in commiteevecpros"
              :key="item.commiteeVecProIdCard"
            >
              <div class="text-center pa-0">
                ลงชื่อ.............................................. <br />
                ( {{ item.title_s + item.frist_name + " " + item.last_name }} )
                <br />
                <span v-if="item.commiteeVecProPosition === '1'"
                  >ประธานกรรมการ</span
                >
                <span v-if="item.commiteeVecProPosition === '2'"
                  >กรรมการ</span
                >
                <span v-if="item.commiteeVecProPosition === '3'"
                  >กรรมการ</span
                >
                <span v-if="item.commiteeVecProPosition === '4'"
                  >กรรมการ</span
                >
                <span v-if="item.commiteeVecProPosition === '5'"
                  >กรรมการ</span
                >
                <span v-if="item.commiteeVecProPosition === '6'"
                  >กรรมการ</span
                >
                <span v-if="item.commiteeVecProPosition === '7'"
                  >เลขานุการ</span
                >
              </div>
            </v-col>
          </v-row>
        <!-- New Page -->
        <p style="page-break-before: always;" class="mb-15">&nbsp;</p>
            <!-- 2 -->
        </div>
         
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    ApiKey: "HRvec2021",
    loading: true,
    dialogLoading: false,
    periods: [],
    transference_personnels: [],
    commiteevecpros: []
  }),

  async mounted() {
    await this.periodQuery();
    await this.commiteevecprosQueryAll();
    await this.transference_personnelQueryAll();
  },

  methods: {
    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period_vecprovince.php", {
        ApiKey: this.ApiKey,
        period_vecprovince_enable: "1",
        period_vecprovince_type: "discuss_teacher"
      });
      this.periods = result_period.data;
      if (this.periods.period_vecprovince_enable != "1")
        this.$router.push("/vecprovince");
    },

    async commiteevecprosQueryAll() {
      let result = await this.$http.post("commiteevecpro.php", {
        ApiKey: this.ApiKey,
        commiteevecproviceID: this.url_result,
        commiteeVecProYear: this.periods.period_vecprovince_year,
        commiteeVecProTime: this.periods.period_vecprovince_times
      });
      this.commiteevecpros = result.data;
      
    },

    async transference_personnelQueryAll() {
      let result = await this.$http.post("transference_personnel.php", {
        ApiKey: this.ApiKey,
        province_ID: this.url_result,
        time_s: this.periods.period_vecprovince_times,
        year_s: this.periods.period_vecprovince_year,
        type_move: "nm"
      });
      this.transference_personnels = result.data;
    },

    Export2Doc(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-16'><title>Export HTML To Doc</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msword"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-word;charset=utf-16," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".doc" : "document.doc";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }

      document.body.removeChild(downloadLink);
    }
  },
  computed: {
    url_result() {
      let result = window.location.href;
      let split_s = result.split("/");
      return split_s[6];
    }
  },
  props: {
    source: String
  }
};
</script>
<style>
body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
}

.u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}
u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.table,
.th,
.td {
  border-bottom: 0.5px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
}

.text_j {
  border-collapse: collapse;
  text-align: justify;
  text-justify: inter-word;
  line-height: 1;
}
.text_l {
  border-collapse: collapse;
  text-align: left;
  text-justify: inter-word;
  line-height: 1;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}

td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}

#verticaltext {
  writing-mode: tb-rl;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 21cm;
  min-height: 29.7cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 1cm;
  padding-right: 1cm;
  padding-bottom: 0.5cm;
  padding-left: 1cm;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 22pt;
  font-weight: bold;
  line-height: 1.5;
}

.regular14 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.bold14 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}
.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}
.td_line {
  border-bottom: 1pt dotted black;
}
/*.subpage {
          height: 247mm;
        }*/

.BlackBold10 {
  font-weight: bold;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}
.text_al {
  text-align: left;
  vertical-align: top;
}
#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}
.textAlignVer {
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: relative;
  margin-bottom: 2px;
}
.bgcolor{
  background: rgb(206, 206, 206);
}
@media print {
  .page {
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    padding-top: 1cm;
    padding-right: 1cm;
    padding-bottom: 1cm;
    padding-left: 1.5cm;
  
  }
  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  /* .head {
            font-size: 18px;
            font-weight: bold;
          }
          .regular12 {
            font-size: 12px;
          }
          .blod12 {
            font-size: 12px;
            font-weight: bold;
          }
          .blackRegula8 {
            font-size: 8px;
          } */
  .noprint {
    display: none;
  }

  .gap-10 {
    width: 100%;
    height: 10px;
  }
  .gap-20 {
    width: 100%;
    height: 20px;
  }
  .gap-30 {
    width: 100%;
    height: 30px;
  }
}
</style>
